import type React from 'react'
import LogoPath from '~/images/logo/logo-potential-cloud.png'

const CenterLoading: React.FC = () => {
  return (
    <div className='fixed top-0 left-0 z-[9999] flex items-center justify-center h-screen w-screen'>
      <img src={LogoPath} alt='POTENTIAL CLOUD' className='h-[60px] w-auto' />
    </div>
  )
}
export default CenterLoading
